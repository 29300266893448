<template>
  <tr>
    <td>
      {{ row.customer }}
    </td>
    <td>
      {{ row.businessUnitName }}
    </td>
    <td>
      {{ row.city }}, {{ row.state }}
    </td>
    <td>
      {{ row.category }}
    </td>
    <td>
      {{ row.jobStatus }}
    </td>
    <td>
      {{ row.remoteCompletedAt }}
    </td>
    <td>
      {{ row.tenant }}
    </td>
    <td>
      {{ row.invoicesTotal }}
    </td>
    <td>
      <a :href="`/gb/service_titan/jobs/${row.id}`">
        View
      </a>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    row: { type: Object, required: true }
  }
}
</script>
