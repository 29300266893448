<template>
  <div class="location-call-tracking">
    <template v-if="phoneNumber">
      <div class="location-call-tracking__top">
        <h2 class="location-call-tracking__title content-title">Call tracking</h2>
        <span class="location-call-tracking__range">
          Past 7 days.
        </span>
        <div class="location-call-tracking__link">
          <a
            class="btn btn-xs"
            target="_blank"
            :href="`/call_tracking/incoming_calls?byLocation=${locationId}`">
            View all calls
          </a>
        </div>
      </div>
      <div class="location-call-tracking__info">
        <div class="location-call-tracking__phone">{{ phoneNumber.number }}</div>
        <div
          class="location-call-tracking__status"
          :class="`location-call-tracking__status--${phoneNumber.status.replace('_', '-')}`">
          <i :class="statusIcons[phoneNumber.status]" />
          <span>{{ humanize(phoneNumber.status) }}</span>
        </div>
        <a
          class="location-call-tracking__configure"
          :href="`/call_tracking/phone_numbers/${phoneNumber.obfuscatedId}/edit`"
          id="config-number-link">
          <i class="fa-regular fa-gear" />
        </a>
        <b-popover
          placement="top"
          target="config-number-link"
          custom-class="geogrids-popover p-1 text-center"
          triggers="hover">
          Configure this number
        </b-popover>
        <div
          v-if="callTrackingWarning"
          class="location-call-tracking__warning"
          :class="`location-call-tracking__warning--${callTrackingWarning.type}`">
          <i class="fa-solid fa-octagon-exclamation" />
          <span>{{ callTrackingWarning.message }}</span>
        </div>
      </div>
      <IncomingCallsStats :filters="filters" :fetch-on-mounted="true" />
    </template>
    <template v-else>
      <h2 class="location-call-tracking__title content-title mb-4">Call tracking</h2>
      <CallTrackingInstruction :instruction-step-info="instructionStepInfo" :is-location-page="true" />
    </template>
  </div>
</template>

<script>
import { daysAgo, dateWithoutTime, humanize } from 'common/helpers'
import { PHONE_STATUS_ICONS } from 'vue_widgets/jensen/consts'

import IncomingCallsStats from 'vue_widgets/jensen/incoming_calls/sections/incoming_calls_stats'
import CallTrackingInstruction from './instruction.vue'

export default {
  components: {
    IncomingCallsStats,
    CallTrackingInstruction
  },
  props: {
    locationId: {
      type: String,
      required: true
    },
    phoneNumber: {
      type: Object,
      required: false
    },
    instructionStepInfo: {
      type: Object,
      required: false
    }
  },
  created() {
    this.filters = {
      byLocation: this.locationId,
      callStatuses: ['answered', 'voicemail', 'missed'],
      fromDate: daysAgo(7),
      toDate: dateWithoutTime(new Date())
    }

    this.statusMessageMap = {
      not_configured: {
        type: 'warning',
        message: 'You need to configure the phone number in the settings.'
      },
      unavailable: {
        type: 'error',
        message: 'The number has been removed'
      },
      inactive: {
        type: 'warning',
        message: 'The phone number is deactivated in the settings.'
      }
    }

    this.statusIcons = PHONE_STATUS_ICONS
  },
  computed: {
    callTrackingWarning() {
      return this.statusMessageMap[this.phoneNumber.status]
    }
  },
  methods: {
    humanize
  }
}
</script>
