import Vue from 'vue'
import camelCaseKeys from 'camelcase-keys-deep'
import JobsIndex from 'vue_widgets/mobile/service_titan/jobs/index'
import JobMomentCreating from 'vue_widgets/mobile/service_titan/jobs/moment_creating'

Styxie.Initializers.MobileServiceTitanJobs = {
  index: (params) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-service-titan-jobs-index'),
        render: (h) => h(JobsIndex, { props: camelCaseKeys(params) })
      })
    })
  },
  show: (params) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-service-titan-create-checkin'),
        render: (h) => h(JobMomentCreating, { props: camelCaseKeys(params) })
      })
    })
  }
}
