/* eslint-disable func-names */
export default function PredefinedRanges() {
  return function(fp) {
    const pluginData = {
      ranges: typeof fp.config.ranges !== 'undefined' ? fp.config.ranges : {},
      rangesNav: $('<ul>').addClass('flatpickr-predefined-ranges'),
      rangesButtons: {}
    }

    const addRangeButton = (label) => {
      pluginData.rangesButtons[label] = $('<button>')
        .addClass('flatpickr-predefined-ranges__button')
        .attr('type', 'button')
        .text(label)

      pluginData.rangesNav.append($('<li>').addClass('flatpickr-predefined-ranges__item').append(pluginData.rangesButtons[label]))
      return pluginData.rangesButtons[label]
    }

    const highlightActiveRangeButton = (selectedDates) => {
      pluginData.rangesNav.find('.active').removeClass('active')

      if (selectedDates.length > 0) {
        const startDate = moment(selectedDates[0])
        const endDate = selectedDates.length > 1 ? moment(selectedDates[1]) : startDate

        Object.keys(pluginData.ranges).some((label) => {
          const range = pluginData.ranges[label]

          if (startDate.isSame(range[0], 'day') && endDate.isSame(range[1], 'day')) {
            pluginData.rangesButtons[label].addClass('active')
            return true
          }

          return false
        })
      }
    }

    return {
      onReady(selectedDates) {
        Object.keys(pluginData.ranges).forEach((label) => {
          const range = pluginData.ranges[label]

          addRangeButton(label)
            .on('click', function() {
              $(this).blur()
              const firstDate = fp.config.minDate.getTime() < new Date(range[0]).getTime() ? range[0] : fp.config.minDate
              fp.setDate([firstDate, range[1]], true)
              fp.close()
            })
        })

        if (pluginData.rangesNav.children().length > 0) {
          $(fp.calendarContainer).prepend(pluginData.rangesNav)
          highlightActiveRangeButton(selectedDates)
        }
      },
      onValueUpdate(selectedDates) {
        highlightActiveRangeButton(selectedDates)
      },
      onOpen(selectedDates) {
        highlightActiveRangeButton(selectedDates)
      }
    }
  }
}
/* eslint-enable func-names */
