<template>
  <div class="jobs-moment-creating">
    <a v-if="checkinId" :href="checkingUrl" class="btn btn-primary gap-2 px-3 rounded">
      <i class="far fa-pen-to-square" />
      View moment
    </a>
    <button v-else :disabled="!readyForCheckin" class="btn btn-primary gap-2 px-3 rounded" @click="toogleModal">
      <i class="far fa-pen-to-square" />
      Create a moment
    </button>

    <div v-if="isModalShown" class="jobs-moment-creating__backdrop">
      <div class="jobs-moment-creating__modal">
        <div class="jobs-moment-creating__modal__head">
          Select Business
        </div>
        <div class="jobs-moment-creating__modal__body">
          <p>
            To create a moment, you first need to specify the business it will be created from.
          </p>
          <div :class="['form-field', { error: errorText }]">
            <label class="m-0">Business</label>
            <Multiselect
              v-model="business"
              :options="businessesOptions"
              :max-height="540"
              :limit="300"
              :options-limit="300"
              :allow-empty="false"
              track-by="id"
              label="name"
              placeholder="Pick a business">
              <template #option="{ option }">
                <div :class="['text-truncate', { 'text-grey': option.$isDisabled }]">
                  <span>{{ option.name }}</span>
                </div>
                <small v-if="option.disconnected" class="text-muted">GBP API connection isn't active</small>
                <span :class="['d-block font-size-14 text-truncate', { 'text-grey': option.$isDisabled }]">
                  {{ option.locationData.address }}
                </span>
                <span :class="['d-block font-size-14 text-truncate', { 'text-grey': option.$isDisabled }]">
                  {{ option.locationData.storeCode }}
                </span>
              </template>
            </Multiselect>
            <span v-if="errorText" class="mt-1 small text-danger">{{ errorText }}</span>
          </div>
        </div>
        <div class="jobs-moment-creating__modal__footer">
          <button class="btn" @click="toogleModal">
            Close
          </button>
          <button class="btn btn-primary" :disabled="isRequested" @click="createMoment">
            Create a moment
          </button>
        </div>
        <div class="jobs-moment-creating__modal__close" @click="toogleModal">
          <i class="far fa-times" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import axiosTransform from 'common/axios'
import { subscribe, removeChannel } from 'vue_widgets/audit_report_configurations/helpers'
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'

export default {
  components: { Multiselect },
  props: {
    createCheckinUrl: { type: String, required: true },
    readyForCheckin: { type: Boolean, required: true },
    existedCheckinId: { type: String, default: '' },
    businesses: { type: Array, default: () => []}
  },
  data() {
    return {
      isModalShown: false,
      business: null,
      isRequested: false,
      errorText: '',
      jids: [],
      checkinId: this.existedCheckinId
    }
  },
  created() {
    this.channel = subscribe('ServiceTitan::CreateCheckinChannel', (msg) => {
      if (!this.jids.includes(msg.jid)) return

      if (msg.failed) {
        toastr.error('Moment was not created.')
      } else if (msg.id) {
        this.checkinId = msg.id
        toastr.success(`<a href='${this.checkingUrl}' class="text-underline" target='_blank'>Moment</a> successfully created.`)
      }
    })
  },
  computed: {
    businessesOptions() {
      return this.businesses.map((business) => ({
        ...business,
        $isDisabled: !business.active
      }))
    },
    checkingUrl() {
      if (!this.checkinId) return ''

      return `/gb/moments/${this.checkinId}/edit`
    }
  },
  methods: {
    toogleModal() {
      this.isModalShown = !this.isModalShown
    },
    createMoment() {
      this.errorText = ''

      if (!this.business) {
        this.errorText = 'Please select a business.'
        return
      }

      this.isRequested = true

      axios.post(this.createCheckinUrl, { businessId: this.business.id }, axiosTransform)
        .then((res) => {
          this.jids.push(res.data.jid)
          toastr.success('Processing...')
          this.toogleModal()
        })
        .catch((err) => {
          if (err.response.status === 422 && err.response.data.type === 'validation') {
            toastr.error(err.response.data.text)
          } else {
            if (err?.response?.status === 403) return
            toastr.error(DEFAULT_ERROR_MESSAGE)
          }
        })
        .finally(() => {
          this.isRequested = false
        })
    }
  },
  beforeDestroy() {
    removeChannel(this.channel)
  }
}
</script>
