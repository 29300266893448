<template>
  <div class="incoming-calls-filters">
    <div v-for="(field, fieldName) in fields"
      :key="fieldName"
      class="incoming-calls-filters__field">
      <span v-if="field.label">{{ field.label }}</span>
      <span v-else-if="!field.options?.label">&nbsp;</span>
      <component
        :is="field.component"
        v-model="field.value"
        v-bind="field.options"
        :values="field.values" />
    </div>
    <button
      class="incoming-calls-filters__reset btn btn-link btn--rem gap-3 ml-auto"
      :class="{ 'text-muted': !anyFiltersSelected }"
      :disabled="!anyFiltersSelected"
      @click="resetFilters">
      <i class="far fa-eraser" />
      <span>Clear filters</span>
    </button>
  </div>
</template>

<script>
import qs from 'qs'

import InputSearch from 'vue_widgets/components/input_search_ui'
import CustomSelect from 'vue_widgets/components/custom_select'
import Datepicker from 'vue_widgets/components/datepicker'

import { daysAgo, dateWithoutTime } from 'common/helpers'

export default {
  props: {
    locations: {
      type: Array,
      required: true
    }
  },
  components: {
    InputSearch,
    CustomSelect,
    Datepicker
  },
  data() {
    return {
      fields: {
        query: {
          component: 'InputSearch',
          value: '',
          options: {
            placeholder: 'Search'
          }
        },
        byLocation: {
          component: 'CustomSelect',
          value: '',
          values: this.locations,
          options: {
            label: 'Location',
            placeholder: 'All',
            trackBy: 'id',
            allowEmpty: true
          }
        },
        callStatuses: {
          component: 'CustomSelect',
          value: ['answered', 'voicemail', 'missed'],
          values: [
            { value: 'answered', text: 'Answered' },
            { value: 'voicemail', text: 'Voicemail' },
            { value: 'missed', text: 'Missed' },
            { value: 'spam', text: 'Spam' }
          ],
          options: {
            label: 'Statuses',
            allowEmpty: true,
            multiple: true,
            noTags: true
          }
        },
        dateRange: {
          component: 'Datepicker',
          value: [daysAgo(29), dateWithoutTime(new Date())],
          label: 'Date range',
          options: {
            mode: 'range',
            showRangePresets: true,
            showMonths: 2,
            dateFormat: 'Y/m/d',
            minDate: dateWithoutTime(new Date('1/1/2025')),
            maxDate: dateWithoutTime(new Date())

          }
        }
      }
    }
  },
  created() {
    this.defaultFilters = JSON.parse(JSON.stringify(this.formattedFilters))
    this.handleQueryFilters()
  },
  computed: {
    formattedFilters() {
      const mappedValues = {}
      Object.keys(this.fields).forEach((filterName) => {
        mappedValues[filterName] = this.fields[filterName].value
      })
      const { dateRange, ...rest } = mappedValues
      const [fromDate, toDate] = (dateRange ?? [])
      return {
        fromDate,
        toDate,
        ...rest
      }
    },
    anyFiltersSelected() {
      const { fromDate, toDate, ...rest } = this.defaultFilters
      const [currentFromDate, currentToDate] = this.fields.dateRange.value
      if (currentFromDate !== fromDate || currentToDate !== toDate) return true
      let selected = false
      Object.keys(rest).some((filterName) => {
        const defaultValue = rest[filterName]
        const currentValue = this.fields[filterName].value
        if (Array.isArray(defaultValue)) {
          selected = JSON.stringify(currentValue.toSorted()) !== JSON.stringify(defaultValue.toSorted())
        } else {
          selected = defaultValue !== currentValue
        }
        return selected
      })
      return selected
    }
  },
  methods: {
    handleQueryFilters() {
      const search = qs.parse(window.location.search, { ignoreQueryPrefix: true })
      Object.keys(search).forEach((searchKey) => {
        const presetFilterValue = search[searchKey]
        const field = this.fields[searchKey]
        if (!field) return
        field.value = presetFilterValue
      })
    },
    resetFilters() {
      const { fromDate, toDate, ...rest } = this.defaultFilters
      this.fields.dateRange.value = [fromDate, toDate]
      Object.keys(rest).forEach((filterName) => {
        this.fields[filterName].value = rest[filterName]
      })
    }
  },
  watch: {
    formattedFilters: {
      handler() {
        this.$emit('updateFilters', this.formattedFilters)
      },
      deep: true,
      immediate: true
    },
    locations() {
      this.fields.byLocation.values = this.locations
    }
  }
}
</script>
